import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Link from "next/link";
import { useQuery } from "react-query";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Gleap from "gleap";
import Button from "../CommonSection/Buttons/Button";
import Input from "../CommonSection/Input/Input";
import classes from "./Login.module.scss";
import { getMasterData } from "../../lib/api/generalApi";
import { login as loginAction } from "../../Store/Slices/AuthSlice";
import { getMasterData as getMasterDataAction } from "../../Store/Slices/MasterSlice";
import { censorEmail, censorPhone, setCookie, wait } from "../../utils/general";
import { errorToast, successToast } from "../../utils/toast";
import { ROLE_SHORT_CODE } from "../../utils/status";
import { login, resendVerifcationMail, verifyEmail, verifyOTP } from "../../lib/api/authApi";
import OTPInput from "./OTPInput";

const initialValues = {
  email: "",
};

const validateEmail = (email: string | undefined) => Yup.string().email().isValidSync(email);

const validatePhone = (phone: number | undefined) =>
  Yup.number()
    .integer()
    .positive()
    .test((nPhone) => !!(nPhone && nPhone.toString().length >= 8 && nPhone.toString().length <= 12))
    .isValidSync(phone);

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your Email or Phone")
    .trim()
    .test(
      "email",
      "Email or Phone is invalid",
      (value) => validateEmail(value) || validatePhone(parseInt(value || "0", 10)),
    ),
});

const Register: React.FC = (): JSX.Element => {
  const router = useRouter();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [showOTP, setShowOTP] = useState(false);
  const [isRetryLink, setIsRetriveLink] = useState(false);
  const [timer, setTimer] = useState(0);
  const [otpError, setOTPError] = useState("");
  const [OTP, setOTP] = useState<string[]>(Array(6).fill(""));
  const [userData, setUserData] = useState<Record<string, any>>();
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => (currTimer > 0 ? currTimer - 1 : currTimer)),
    [],
  );

  useEffect(() => {
    if (timer > 0) setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const masterApi = useQuery({
    queryKey: ["getMasterData"],
    queryFn: () => getMasterData(),
    onSuccess: async (data) => {
      dispatch(getMasterDataAction(data.data.data));
      // await wait(200);
      // window.location.replace("/dashboard");
    },
    enabled: false,
  });

  // useEffect(() => {
  //   masterApi.refetch();
  // }, []);

  const { values, errors, touched, handleSubmit, handleBlur, handleChange } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (fValues): Promise<void> => {
      const buttonEle = document.getElementById("login_btn") as HTMLButtonElement;
      buttonEle.disabled = true;
      setLoading(true);
      let reqData: Record<string, any> = {
        login_id: fValues?.email?.trim(),
      };

      if (showOTP) {
        if (!(OTP?.join("")?.length === 6)) {
          setOTPError("Please enter valid OTP.");
          setLoading(false);
          buttonEle.disabled = false;
          return;
        }
        reqData = {
          ...reqData,
          user_id: userData?.id,
          otp: OTP?.join(""),
        };
        try {
          const res = await verifyOTP(reqData);
          if (res.data.status.code === 200 || res.data.status.code === 201) {
            if ([ROLE_SHORT_CODE.BMS_ADMIN].includes(res.data.data.profile.role_sc)) {
              const { token, refreshToken } = res.data.data.authentication;
              setCookie(token, refreshToken);
              const uData = res.data.data;
              Gleap.identify(uData?.id, {
                name: `${uData?.profile?.first_name ? uData?.profile?.first_name : ""} ${
                  uData?.profile?.last_name ? uData?.profile?.last_name : ""
                }`,
                email: uData?.email,
              });
              await wait(400);
              dispatch(loginAction(res.data.data));
              masterApi.refetch();
            } else {
              errorToast("Invalid User!");
            }
            setLoading(false);
            buttonEle.disabled = false;
          }
        } catch (error: any) {
          errorToast(error?.response?.data?.message || "Something went wrong! Please try again");
          setLoading(false);
          buttonEle.disabled = false;
        }
      } else {
        try {
          const res = await login(reqData);
          if (res.data.status.code === 200 || res.data.status.code === 201) {
            if (
              ![ROLE_SHORT_CODE.SUPER_ADMIN, ROLE_SHORT_CODE.SUPER_EMPLOYEE].includes(
                res.data.data.profile.role_sc,
              )
            ) {
              setUserData(res.data.data);
              const nEmail = censorEmail(res.data.data?.email || "");
              const nPhone = censorPhone(res.data.data?.phone || "");
              let msg = `OTP for login has been successfully sent to your email at ${nEmail || ""}`;
              if (res.data.data?.profile?.is_phone_verified) {
                msg += ` and mobile number ${nPhone}`;
              }
              successToast(`${msg}.`);
              setShowOTP(true);
              setTimer(60);
            } else {
              errorToast("Invalid User!");
            }
            setLoading(false);
            buttonEle.disabled = false;
          }
        } catch (error: any) {
          if (error.response.data.message === "Please verify your email first!") {
            errorToast("Email verification is pending.");
            setIsRetriveLink(true);
          } else {
            errorToast(error?.response?.data?.message || "Something went wrong! Please try again");
          }
          setLoading(false);
          buttonEle.disabled = false;
        }
      }
    },
  });

  const resendLinkHandler = async () => {
    setLoading(true);
    const res = await resendVerifcationMail({ login_id: values.email?.trim() });
    if (res.data.status.code === 200 || res.data.status.code === 201) {
      successToast("Email sent Successfully.");
      setLoading(false);
      setIsRetriveLink(false);
    } else {
      errorToast("Something went wrong.");
      setLoading(false);
    }
  };

  const resendOTP = async () => {
    const buttonEle = document.getElementById("login_btn") as HTMLButtonElement;
    buttonEle.disabled = true;
    setLoading(true);
    try {
      const res = await login({ login_id: values?.email?.trim() });
      if (res.data.status.code === 200 || res.data.status.code === 201) {
        setUserData(res.data.data);
        const nEmail = censorEmail(res.data.data?.email || "");
        const nPhone = censorPhone(res.data.data?.phone || "");
        let msg = `OTP for login has been successfully sent to your email at ${nEmail || ""}`;
        if (res.data.data?.profile?.is_phone_verified) {
          msg += ` and mobile number ${nPhone}`;
        }
        successToast(`${msg}.`);
        setShowOTP(true);
        setTimer(60);
        setLoading(false);
        buttonEle.disabled = false;
      }
    } catch (error: any) {
      if (error?.response?.data?.message === "Please verify your email first!") {
        errorToast("Email verification is pending.");
        setIsRetriveLink(true);
      } else {
        errorToast(error?.response?.data?.message || "Something went wrong! Please try again");
      }
      setLoading(false);
      buttonEle.disabled = false;
    }
  };

  const verifyEmailHandler = async () => {
    setLoading(true);
    const res = await verifyEmail({ user_id: router.query.id });
    if (res.data.status.code === 200 || res.data.status.code === 201) {
      // successToast("Email Verification successfully!");
      successToast("Email Verified!");
      setLoading(false);
    } else {
      errorToast("Something Went Wrong!");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (router?.query?.id) {
      verifyEmailHandler();
    }
  }, [router.query.id]);

  return (
    <div className={`${classes.login_section}`}>
      <div className={`${classes.login_container}`}>
        <div className={`${classes.login_box}`}>
          {/* <div className="w-full h-full bg-[url('/images/loginBg.png')] bg-cover bg-bottom bg-no-repeat">
            <Swiper
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              autoplay={{
                delay: 3000,
              }}
              pagination={{ clickable: true, type: "bullets" }}
              className="w-full h-[95%]"
            >
              <SwiperSlide>
                <LoginWelcome />
              </SwiperSlide>
              <SwiperSlide>
                <LoginWelcomeContentTwo />
              </SwiperSlide>
            </Swiper>
          </div> */}
          <div className={`${classes.login_separates}`}>
            <div className={`${classes.login_card}`}>
              {/* <div className="flex flex-col text-font-textcolor1 font-display justify-start h-full">
                <div className="flex flex-col gap-1">
                  <div className={`text-subtitle1 text-font-textcolor4`}>
                    Accelerating India’s Transition into Sustainable Transportation
                  </div>
                  <div className={`text-body1 text-font-textcolor5`}>
                    A Groundbreaking Software Platform to help EV, Battery, Motor, and VCU OEMs
                    accelerate hardware innovations.
                  </div>
                </div>
              </div> */}

              <div className={`${classes.login_form_separates}`}>
                <form onSubmit={handleSubmit} className={`${classes.login_details_separates}`}>
                  <div className="flex flex-col pb-5">
                    <h1 className={`text-subtitle1 font-semibold -mt-3`}>Login to Your Account</h1>
                    {/* <h1 className={`text-h5 font-semibold text-primary-main -mt-3`}>Sign in</h1> */}
                    {/* <h1 className={`text-smalltext font-medium text-font-textcolor5`}>
                      Sign in to Your Account
                    </h1> */}
                  </div>
                  <div className="flex flex-col gap-6">
                    <Input
                      id="email"
                      label="Email or Phone"
                      // shrink
                      type="text"
                      value={values.email}
                      error={errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.email}
                      disabled={showOTP}
                      className="!w-[350px]"
                    />
                    <div className="hidden">
                      <input id="password" type="Password" value="1234567" />
                    </div>
                    {showOTP && (
                      <div className="flex flex-col w-full gap-5 h-fit text-font-textcolor4 -mb-4 text-body1">
                        Enter OTP
                      </div>
                    )}
                    {showOTP && <OTPInput length={6} setOTP={setOTP} />}
                    <div className={`${classes.login_pw} -mt-3 text-body1 justify-between`}>
                      {showOTP && (
                        <span
                          aria-hidden
                          onClick={timer ? undefined : resendOTP}
                          className={
                            timer ? "cursor-default text-font-textcolor5" : "cursor-pointer"
                          }
                        >
                          {timer
                            ? `Resend OTP in 00:${(timer || 0).toString().padStart(2, "0")}`
                            : "Resend OTP"}
                        </span>
                      )}
                      {isRetryLink && !showOTP && (
                        <span aria-hidden onClick={resendLinkHandler} className="cursor-pointer">
                          Resend Verification Email
                        </span>
                      )}
                      {otpError && (
                        <span className="flex items-center font-medium text-error-main text-xs mt-1">
                          {otpError as string}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="flex justify-end pt-5">
                    <Button
                      id="login_btn"
                      className="lg:h-[40px]"
                      variant="primary"
                      type="submit"
                      tooltip="Login"
                    >
                      <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#fff"
                        ariaLabel="three-dots-loading"
                        visible={isLoading}
                      />
                      {!isLoading && (showOTP ? "Login" : "Next")}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <div className="text-body1 text-font-textcolor4 flex flex-col justify-center items-center">
              <span>&copy; {new Date().getFullYear()} IntuEnergy Technologies Pvt. Ltd.</span>
              <span>
                All Rights Reserved.{" "}
                <Link href="https://intuions.com/privacy-policy/" passHref>
                  <a target="_blank" className="text-primary-main hover:underline cursor-pointer">
                    Privacy Policy
                  </a>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
